/* CSS Document */
@tailwind components;
@tailwind utilities;

@layer utilities {
    .smallcaps {
        //text-transform: none !important;
        font-variant: small-caps;
    }
}
